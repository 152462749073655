@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.ttf");
  font-family: "Roboto";
  src: url("./fonts/Roboto-Light.ttf");
  font-family: "Lora";
  src: url("./fonts/Lora-Italic.ttf");
}

html {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  background-color: #1e252b;
}

body {
  margin: 0;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  top: 26px;
  padding: 4px 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #1e252b2d;
  backdrop-filter: blur(8px);
  .header-width {
    height: 100%;
    max-width: 1440px;
    min-width: 1224px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > * {
      height: 100%;
      button {
        height: 100%;
        padding: 0 1.3em;
        border-style: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background-color: inherit;
        color: rgba(255, 255, 255, 0.55);
        transition: color 0.4s;
        &:hover {
          color: rgba(255, 232, 127, 0.856);
        }
      }
    }
    .header-logo {
      display: flex;
      align-items: center;
      .logo-txt {
        margin-left: 12px;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: rgba(255, 255, 255, 0.9);
        user-select: none;
      }
    }
    .header-regist {
      .signup-btn {
        width: 118px;
        background-color: #ff6e30;
        border-radius: 8px;
        color: #fdf5ff;
        font-weight: 600;
        transition: background-color 0.4s;
        &:hover {
          background-color: #ce4d16;
        }
      }
      .login-btn {
        padding: 0 2em;
        color: rgba(255, 255, 255, 0.78);
        transition: color 0.4s;
        &:hover {
          color: rgba(255, 232, 127, 0.856);
        }
      }
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;

  .screen1 {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .search {
      width: 600px;
      margin-left: 108px;
      .search-h1 {
        font-weight: 500;
        font-size: 50px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.9);
        span {
          color: #ff6e30;
        }
      }
      .search-txt {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.55);
      }
      .search-pan {
        width: 497.66px;
        height: 64px;
        margin-top: 10px;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(#283036, #283036) padding-box,
          linear-gradient(to right, #ffffff1a, #ffffff00) border-box;
        border: 1.5px solid transparent;
        .search-svg {
          margin-left: 18px;
        }
        .search-input {
          border-style: none;
          outline-style: none;
          flex-grow: 1;
          margin-left: 8px;
          margin-right: 8px;
          background: #283036;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.38);
        }
        .search-btn {
          border-width: 0;
          width: 130.43px;
          height: 48px;
          margin-right: 11px;
          background-color: #ff6e30;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          transition: background-color 0.3s;
          &:hover {
            background-color: #ce4d16;
          }
        }
      }
      .medsos {
        margin-top: 110px;
        width: 190px;
        height: 58px;
        & > svg {
          padding: 12px;
          &:hover path {
            transition: fill 0.3s;
            fill: #a60cff;
          }
        }
      }
    }
  }

  .screen2 {
    margin-top: 200px;
    width: 1224px;
    height: 1109px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .tools-txt {
      .tools-txt1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 25px;
        text-align: center;
        color: rgba(255, 255, 255, 0.9);
      }
      .tools-txt2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: rgba(255, 255, 255, 0.55);
      }
    }
    .tools1,
    .tools2 {
      height: 314px;
      min-width: 1224px;
      max-width: 1440px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .tool {
        height: 294px;
        width: 392px;
        background: linear-gradient(#ffffff00, #ffffff00) padding-box,
          linear-gradient(to right, #ffffff00, #ffffff00, #ffffff00, #ffffff00)
            border-box;
        border: 2px solid transparent;
        border-radius: 15px;
        transition: all 0.3s;
        &:hover {
          transform: translate(0, -20px);
          backdrop-filter: blur(25px);
          background: linear-gradient(
                rgba(40, 48, 54, 0.6),
                rgba(40, 48, 54, 0.6)
              )
              padding-box,
            linear-gradient(
                30deg,
                #ffffff07,
                #ffffff14,
                #ffffff17 50%,
                #ffffff0e,
                #ff6e304f
              )
              border-box;
          border: 2px solid transparent;
          .tool-in {
            .tool-in1 {
              .tool-in1-txt {
                .tool-in1-txt1 {
                  color: rgba(255, 255, 255, 0.9);
                }
                .tool-in1-txt2 {
                  color: #ffa537;
                }
              }
            }
            .tool-in2-txt {
              color: rgba(255, 255, 255, 0.78);
            }
            .tool-control {
              .tool-control-btns {
                & > button {
                  background-color: #283036;
                  box-shadow: 0px 5px 10px -3px rgba(18, 18, 18, 0.15);
                  & > svg path {
                    fill-opacity: 55%;
                  }
                }
              }
              .tool-control-btn {
                background-color: #283036;
                box-shadow: 0px 5px 10px -3px rgba(18, 18, 18, 0.15);
                background-color: #ff6e30;
                color: #ffffff;
              }
            }
          }
        }
        .tool-in {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          height: 100%;
          width: 100%;
          padding: 33px;
          .tool-in1 {
            height: 66px;
            display: flex;
            align-items: center;
            .tool-in1-txt {
              padding-left: 30px;
              .tool-in1-txt1 {
                font-weight: 500;
                font-size: 24px;
                line-height: 12px;
                color: rgba(255, 255, 255, 0.78);
              }
              .tool-in1-txt2 {
                font-weight: 400;
                font-size: 18px;
                line-height: 16px;
                color: rgba(255, 165, 55, 0.55);
              }
            }
          }
          .tool-in2-txt {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.55);
          }
          .tool-control {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tool-control-btns {
              height: 42px;
              width: 96px;
              display: flex;
              justify-content: space-between;
              & > button {
                background-color: inherit;
                border-style: hidden;
                border-radius: 8px;
                &:hover {
                  .tool-control-svg1 path {
                    transition: all 0.3s;
                    fill-opacity: 100%;
                    fill: #fd3b41;
                  }
                  .tool-control-svg2 path {
                    transition: all 0.3s;
                    fill-opacity: 100%;
                    fill: #ff6e30;
                  }
                }
              }
            }
            .tool-control-btn {
              height: 42px;
              width: 106px;
              background-color: inherit;
              border-style: hidden;
              border-radius: 8px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.55);
              transition: all 0.3s;
              &:hover {
                background-color: #ce4d16;
              }
            }
          }
        }
      }
    }
    .tools-btn {
      box-sizing: border-box;
      width: 184px;
      height: 64px;
      background-color: #1e252b;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #ff6e30;
      transition: background-color 0.3s;
      &:hover {
        background-color: rgba(40, 48, 54, 0.6);
      }
    }
  }

  .screen3 {
    margin-top: 200px;
    min-width: 1224px;
    max-width: 1440px;
    height: 253px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(#283036, #283036) padding-box,
      linear-gradient(to right, #ffffff0d, #ffffff00) border-box;
    border: 2px solid transparent;
    .brand-txt {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: rgba(255, 255, 255, 0.8);
    }
    .logos {
      display: flex;
      width: 1059px;
      justify-content: space-between;
      align-items: center;
      & > svg:hover path {
        transition: fill 0.3s;
        fill: #ff8c5a;
      }
    }
  }

  .screen4 {
    margin-top: 200px;
    min-width: 1224px;
    max-width: 1440px;
    height: 638px;
    display: flex;
    justify-content: space-between;
    .newcomer-left {
      .newcomer-left-txt1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 50%;
        color: rgba(255, 255, 255, 0.9);
      }
      .newcomer-left-txt2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.55);
      }
      .newcomer-left-btn {
        width: 198px;
        height: 58px;
        background-color: #ff6e30;
        border-style: hidden;
        border-radius: 8px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin-top: 40px;
        color: #ffffff;
        transition: background-color 0.3s;
        &:hover {
          background-color: #ce4d16;
        }
      }
    }
    .newcomer-right {
      width: 600px;
      height: 638px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      .newtool {
        width: 288px;
        height: 284px;
        background: linear-gradient(#ffffff00, #ffffff00) padding-box,
          linear-gradient(to right, #ffffff00, #ffffff00, #ffffff00, #ffffff00)
            border-box;
        border: 2px solid transparent;
        border-radius: 15px;
        transition: all 0.3s;
        &:hover {
          transform: translate(0, -20px);
          background-color: rgba(40, 48, 54, 0.6);
          backdrop-filter: blur(25px);
          background: linear-gradient(
                rgba(40, 48, 54, 0.6),
                rgba(40, 48, 54, 0.6)
              )
              padding-box,
            linear-gradient(
                30deg,
                #ffffff07,
                #ffffff14,
                #ffffff17 50%,
                #ffffff0e,
                #ff6e304f
              )
              border-box;
          border: 2px solid transparent;
          .newtool-in {
            .newtool-in1 {
              .newtool-in1-txt {
                .newtool-in1-txt1 {
                  color: rgba(255, 255, 255, 0.9);
                }
                .newtool-in1-txt2 {
                  color: #ffa537;
                }
              }
            }
            .newtool-in2-txt {
              color: rgba(255, 255, 255, 0.78);
            }
            .tool-control {
              .tool-control-btns {
                & > button {
                  background-color: #283036;
                  box-shadow: 0px 5px 10px -3px rgba(18, 18, 18, 0.15);
                  & > svg path {
                    fill-opacity: 55%;
                  }
                }
              }
              .tool-control-btn {
                background-color: #283036;
                box-shadow: 0px 5px 10px -3px rgba(18, 18, 18, 0.15);
                background-color: #ff6e30;
                color: #ffffff;
              }
            }
          }
        }
        .newtool-in {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          height: 100%;
          width: 100%;
          padding: 28px 22px;
          .newtool-in1 {
            height: 66px;
            display: flex;
            align-items: center;
            .newtool-in1-txt {
              padding-left: 30px;
              .newtool-in1-txt1 {
                font-weight: 500;
                font-size: 24px;
                line-height: 12px;
                color: rgba(255, 255, 255, 0.78);
              }
              .newtool-in1-txt2 {
                font-weight: 400;
                font-size: 18px;
                line-height: 16px;
                color: rgba(255, 165, 55, 0.55);
              }
            }
          }
          .newtool-in2-txt {
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.55);
          }
          .tool-control {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tool-control-btns {
              height: 42px;
              width: 96px;
              display: flex;
              justify-content: space-between;
              & > button {
                background-color: inherit;
                border-style: hidden;
                border-radius: 8px;
                &:hover {
                  .tool-control-svg1 path {
                    transition: all 0.3s;
                    fill-opacity: 100%;
                    fill: #fd3b41;
                  }
                  .tool-control-svg2 path {
                    transition: all 0.3s;
                    fill-opacity: 100%;
                    fill: #ff6e30;
                  }
                }
              }
            }
            .tool-control-btn {
              height: 42px;
              width: 106px;
              background-color: inherit;
              border-style: hidden;
              border-radius: 8px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.55);
              transition: all 0.3s;
              &:hover {
                background-color: #ce4d16;
              }
            }
          }
        }
      }
    }
  }

  .screen5 {
    position: relative;
    margin-top: 200px;
    min-width: 1224px;
    max-width: 1440px;
    height: 600px;
    .david {
      position: absolute;
      height: 492px;
      width: 392px;
      top: 4px;
      left: 194px;
      z-index: 3;
    }
    .david-pants {
      position: absolute;
      width: 200px;
      height: 51px;
      left: 292px;
      bottom: 104px;
      clip-path: polygon(5% 0%, 99% 0%, 100% 100%, 0% 100%);
      background: linear-gradient(
        180deg,
        rgba(40, 48, 54, 0) -44.51%,
        #283036 179.88%
      );
      z-index: 4;
    }
    .bage {
      position: absolute;
      width: 265px;
      height: 91px;
      left: 317px;
      bottom: 137px;
      padding-left: 23px;
      backdrop-filter: blur(9px);
      border-radius: 8px;
      background: linear-gradient(rgba(40, 48, 54, 0.6), rgba(40, 48, 54, 0.6))
          padding-box,
        linear-gradient(to right, #ffffff1a, #ffffff00) border-box;
      border: 1px solid transparent;
      z-index: 4;
      .bage-txt1 {
        font-weight: 500;
        font-size: 26px;
        line-height: 10px;
        letter-spacing: 0.015em;
        color: rgba(255, 253, 253, 0.78);
      }
      .bage-txt2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 10px;
        color: rgba(255, 253, 253, 0.55);
      }
    }
    .leftarrow-btn {
      position: absolute;
      top: 246px;
      background-color: inherit;
      border-style: hidden;
      &:hover svg path {
        transition: all 0.3s;
        stroke-opacity: 1;
        stroke: #ff6e30;
      }
      &:first-of-type {
        left: 16px;
      }
      & ~ .leftarrow-btn {
        right: 16px;
        .leftarrow {
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
      }
    }
    .quote {
      position: absolute;
      top: 29px;
      right: 555px;
      width: 103px;
      height: 320px;
      margin: 0;
      font-family: "Lora";
      font-style: normal;
      font-weight: 500;
      font-size: 250px;
      line-height: 320px;
      background: linear-gradient(
        188.88deg,
        rgba(252, 252, 252, 0) 20.34%,
        rgba(255, 255, 255, 0.5) 155.72%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      transform: rotate(19.17deg);
    }
    .quote-txt {
      position: absolute;
      width: 393px;
      height: 180px;
      top: 128px;
      right: 188px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.015em;
      color: rgba(255, 255, 255, 0.78);
    }
    .pages {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 168px;
      height: 8px;
      left: 528px;
      bottom: 0;
      .page {
        width: 44px;
        height: 6px;
        background: #283036;
        border-radius: 4px;
        &:nth-child(2) {
          background: #ff6e30;
        }
      }
    }
    .circle-2 {
      position: absolute;
      width: 305.65px;
      height: 305.65px;
      left: 224.17px;
      top: 127.2px;
      border-radius: 152.8px;
      background: rgba(40, 48, 54, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(25px);
      z-index: 2;
    }
    .circle-3 {
      position: absolute;
      width: 380px;
      height: 380px;
      left: 187px;
      top: 90px;
      border-radius: 190px;
      background: rgba(40, 48, 54, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(25px);
      z-index: 1;
    }
    .circle-blue {
      position: absolute;
      width: 33px;
      height: 33px;
      left: 565px;
      bottom: 145px;
      border-radius: 16.5px;
      background-color: #007acc;
      filter: blur(10px);
    }
    .circle-orange {
      position: absolute;
      width: 65px;
      height: 65px;
      left: 465px;
      bottom: 195px;
      border-radius: 32.5px;
      background-color: rgba(255, 110, 48, 0.7);
      filter: blur(40px);
    }
    .circle-purple {
      position: absolute;
      width: 43px;
      height: 43px;
      left: 225px;
      top: 175px;
      border-radius: 21.5px;
      background: #b345f1;
      filter: blur(25px);
    }
  }

  .screen6 {
    margin-top: 250px;
    width: 553px;
    height: 311px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .contribute {
      .contribute-h1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 60%;
        color: rgba(255, 255, 255, 0.9);
      }
      .contribute-txt {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: rgba(255, 255, 255, 0.55);
      }
    }
    .join-pan {
      width: 549px;
      height: 64px;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(#283036, #283036) padding-box,
        linear-gradient(to right, #ffffff1a, #ffffff00) border-box;
      border: 1.5px solid transparent;
      .join-input {
        border-style: none;
        outline-style: none;
        flex-grow: 1;
        margin-left: 8px;
        margin-right: 8px;
        background: #283036;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.38);
      }
      .join-btn {
        border-width: 0;
        width: 130.43px;
        height: 48px;
        margin-right: 11px;
        background-color: #ff6e30;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        transition: background-color 0.3s;
        &:hover {
          background-color: #ce4d16;
        }
      }
    }
  }
}

footer {
  height: 351px;
  min-width: 1224px;
  max-width: 1440px;
  margin-top: 240px;
  padding-top: 70px;
  display: flex;
  justify-content: space-evenly;
  border-top: 0.5px solid rgba(255, 255, 255, 0.15);
  .antools {
    margin-top: 16px;
    .header-logo {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .logo-txt {
        margin-left: 12px;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: rgba(255, 255, 255, 0.9);
        user-select: none;
      }
    }
    .antools-txt1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.9);
    }
    .antools-txt2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: rgba(255, 255, 255, 0.55);
    }
  }
  .contact,
  .category,
  nav {
    font-style: normal;
    font-weight: 500;

    .footer-h2 {
      font-size: 20px;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.9);
    }
    .footer-txt {
      font-size: 16px;
      line-height: 38px;
      color: rgba(255, 255, 255, 0.55);
      text-decoration: none;
    }
    & a:hover {
      transition: color 0.3s;
      color: #ff6e30;
    }
  }
}
